import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import GoogleMapReact from 'google-map-react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import Facilities from '../components/facilities'
import BookingForm from '../components/bookingform'
import Testimonial from '../components/Testimonial'

import '../style/singlepage.css'

const AnyReactComponent = () => 
  <a style={{textDecoration:'none'}} href="https://goo.gl/maps/6UwecwgKdbrygtws9" target="_blank" rel="noopener noreferrer">
    <div style={{
      position: 'absolute',
      top: '-50px',
      left: '-30px',
      width:'160px'

    }}> <img width="100" src="https://res.cloudinary.com/dzudmfllt/image/upload/v1587362445/weHotel/iconfinder.png" alt="iconfinder"/>
        <p style={{fontSize:'22px', marginLeft:'-36px', fontFamily:'Cabin, sans-serif', color:'#3e3e3e'}}>We Hotel Penang</p>
    </div>
  </a>

const GoogleMap = () => {
  const center = {
    lat: 5.4100458,
    lng: 100.4082698
  }
  const zoom = 17;

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDoWXsL5JpW3pIOcMSxccbJDaKY9sAK0rs' }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent
          lat={5.4100458}
          lng={100.4082698}
        />
      </GoogleMapReact>
    </div>
  )
}

const Penang = () => {
  const data = useStaticQuery(graphql`
    query ImagesSJ {
      image: file(relativePath: {eq: "sbrgjaya/FCM_2583a.jpg"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      },
      backgroundSJ: file(relativePath: {eq: "beach.jpg"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      },
      roomsSJ: allFile(filter: {relativeDirectory: {eq: "sbrgjaya/rooms"}}, sort: {fields: name}) {
        nodes {
          id
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      discoverSJ: allFile(filter: {relativeDirectory: {eq: "sbrgjaya/discover"}}, sort: {fields: name}) {
        nodes {
          id
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      promoSJ: allFile(filter: {relativeDirectory: {eq: "sbrgjaya/promo"}}, sort: {fields: name}) {
        nodes {
          id
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const roomDetailsSJ = [
    {
      name:"Standard Room",
      fprice:110,
      dprice:68,
      size:98,
      capacity:2,
      facilities: [
        {
          id:1,name:'1 Queen Size Bed'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Flat Screen TV'
        },
        {
          id:4, name:'Air-conditioning'
        }
      ]
    },
    {
      name:"Superior Room",
      fprice:130,
      dprice:78,
      size:110,
      capacity:2,
      facilities: [
        {
          id:1,name:'1 Queen Size Bed'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Flat Screen TV'
        },
        {
          id:4, name:'Air-conditioning'
        }
      ]
    },
    {
      name:"Deluxe Room",
      fprice:150,
      dprice:88,
      size:127,
      capacity:2,
      facilities: [
        {
          id:1,name:'1 Queen Size Bed'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Flat Screen TV'
        },
        {
          id:4, name:'Air-conditioning'
        }
      ]
    },
    {
      name:"Deluxe Twin Room",
      fprice:150,
      dprice:88,
      size:127,
      capacity:2,
      facilities: [
        {
          id:1,name:'2 Single Beds'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Flat Screen TV'
        },
        {
          id:4, name:'Air-conditioning'
        }
      ]
    },
    {
      name:"Executive Room",
      fprice:190,
      dprice:108,
      size:150,
      capacity:2,
      facilities: [
        {
          id:1,name:'1 Queen Size Bed'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Bathtub'
        },
        {
          id:4, name:'Flat Screen TV'
        },
        {
          id:5, name:'Air-conditioning'
        }
      ]
    },
    {
      name:"We Suite Room",
      fprice:200,
      dprice:128,
      size:180,
      capacity:2,
      facilities: [
        {
          id:1,name:'1 Queen Size Bed'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Jacuzzi'
        },
        {
          id:4, name:'Flat Screen TV'
        },
        {
          id:5, name:'Air-conditioning'
        }
      ]
    },
    {
      name:"Family Room",
      fprice:220,
      dprice:148,
      size:220,
      capacity:4,
      facilities: [
        {
          id:1,name:'2 Queen Size Beds'
        },
        {
          id:2, name:'Hot Shower Bathroom'
        },
        {
          id:3, name:'Flat Screen TV'
        },
        {
          id:4, name:'Air-conditioning'
        }
      ]
    }
  ]

  const itemDetails = [
    {
      name:"Jeruk Madu Pak Ali",
      link:"https://www.jerukmadupakali.com/"
    },
    {
      name:"Uptrak Food Arena",
      link:"https://www.facebook.com/uptrak.penang"
    },
    {
      name:"Sunway Carnival Mall",
      link:"https://www.sunwaycarnival.com/"
    },
    {
      name:"Penang Bird Park",
      link:"https://www.penangbirdpark.com.my/"
    },
    {
      name:"Penang Sentral",
      link:"http://www.penangsentral.com.my/"
    }
  ]
  
  let rooms = data.roomsSJ.nodes;
  rooms.forEach((room,i) => {
    room.name = '';
    room.fprice = ''; 
    room.dprice = ''; 
    room.size = ''; 
    room.capacity = ''; 
    room.facilities = []; 
    i+=0;

    room.name = roomDetailsSJ[i].name;
    room.fprice = roomDetailsSJ[i].fprice;
    room.dprice = roomDetailsSJ[i].dprice;
    room.size = roomDetailsSJ[i].size;
    room.capacity = roomDetailsSJ[i].capacity;
    room.facilities = roomDetailsSJ[i].facilities;
  })

  let discovers = data.discoverSJ.nodes;
  discovers.forEach((discover,i) => {
    discover.name = '';
    discover.link = ''; 
    i+=0;

    discover.name = itemDetails[i].name;
    discover.link = itemDetails[i].link;
  })

  return (
    <Layout>
      <SEO title="We Hotel Penang" />
      <Img 
        fluid={data.image.childImageSharp.fluid}
        alt="We Hotel Penang"
        draggable={false}
      />

      <div className="intro-text">
        <h1 className="heading">We Hotel Penang is a budget hotel with 50 units of rooms located at Permatang Pauh.</h1>
        <p>Started operating since 1st February 2018, after a three month refurbishment. We Café is located at the ground floor of the hotel, make it easy for our guest to enjoy delicious meal without any leaving the hotel.</p>
      </div>
      
      <div className="rooms">
        <div className="box-container">
          {rooms.map(room => 
              <div key={room.id} className="box">
                <Img
                  fluid={room.childImageSharp.fluid}
                />
                <div className="box-detail">
                  <h3
                    style={{
                      fontWeight:'300',
                      fontSize:'28px',
                    }}
                  >{room.name}</h3>
                    <p>
                      <span 
                        style={{
                          fontSize:'36px',
                          color:'#FF5252'
                        }}
                        >RM{room.dprice}
                      </span><span 
                        style={{
                          textDecoration:'line-through',
                          color:'rgb(250, 143, 143)',
                          fontSize:'18px',
                        }}
                      >(RM{room.fprice})
                      </span> / per night
                    </p>
                  <div style={{fontSize:'16px',marginTop:'-13px'}}>
                  Size <span className="facilities-details">{room.size} sq. ft.</span> <span style={{padding:'0 3px'}}></span> Capacity <span className="facilities-details">{room.capacity} persons</span> <br/><br/>
                    <span className="facilities-title">Facilities</span><ul className="facility-list">
                    {room.facilities.map(facility => <li key={facility.id}>{facility.name}</li>)}
                    </ul>
                  </div>
                </div>
              </div>
          )}
          <div className="box-booking">
          <a href="#form"><button className="mobile-button">Book Now</button></a>
          </div>
        </div>
      </div>

      <div className="discover">
          <h1>Discover Penang</h1>
        <div className="box-container-alt">
          {discovers.map(item => (
            <div key={item.id} className="box">
              <div style={{objectFit: "fill"}}>
                <Img
                  fluid={item.childImageSharp.fluid}
                />
                <div className="discover-desc"><a href={item.link} target="_blank" rel="noopener noreferrer"><h3>{item.name}</h3></a></div>
              </div>
            </div>
          ))}
        </div>
        <p>Simple and minimalist look hotel will be easy for you to make decision. 
save money, more efficiency.</p>
      </div>

            {/* CTA */}
            <div className="cta">
        <div className="cta-text">
          <h4 className="heading">The ideal point of departure for your excursions in Penang. From here, guests can make the most of all that the lively city has to offer. With its convenient location, the property offers easy access to the city's must see destinations.</h4>
        </div>

        <div className="cta-button">
          <a href="#form">
            <div className="button">
              Book Now
            </div>
          </a>
        </div>
      </div>

      <div className="promotion">
      <BackgroundImage
          Tag="section"
          fluid={data.backgroundSJ.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <div style={{width:'100vw'}}>
            <div className="box-container" style={{paddingTop:'2rem'}}>
            {data.promoSJ.nodes.map(promo => (
            <div key={promo.id} className="box">
              <Img fluid={promo.childImageSharp.fluid} />
            </div>
          ))}
        </div>
          </div>
        </BackgroundImage>
      </div>

      <Facilities />

      <Testimonial />

      <div className="form-wrapper">
        <div className="form-layer">
          <Img 
            fluid={data.backgroundSJ.childImageSharp.fluid}
            durationFadeIn={1500}
            alt="langkawi beach background"
            style={{filter:'blur(3px)',transform:'scale(1.2)'}}
          />
        </div>

        <div id="form" className="landing background-tint">
          <BookingForm rooms={roomDetailsSJ} />
        </div>
      </div>

      <div className="map">
            <GoogleMap />
      </div>

    </Layout>
  )
}

export default Penang;
